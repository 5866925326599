<template>
  <div>
    <v-list class="sidebar" flat nav>

      <v-list-item-group :class="miniVariant ? 'pa-2' :  'pa-3'" color="primary">

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/animator-view/dashboard">
          <v-list-item-avatar size="24" tile>
            <HomeIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/animator-view/campaigns">
          <v-list-item-avatar size="24" tile>
            <CampaignIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Campagnes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

          <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/animator-view/qr-codes">
          <v-list-item-avatar size="24" tile>
            <QrCodeIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>QR codes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/account">
          <v-list-item-avatar size="24" tile>
            <AccountIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Mon compte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/animator-view/settings">
          <v-list-item-avatar size="24" tile>
            <ConfigIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/animator-view/help">
          <v-list-item-avatar size="24" tile>
            <AlertIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Aide</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>

    </v-list>
  </div>
</template>

<script>
import CampaignIcon from "@/components/svg-icons/CampaignIcon.vue";
import AccountIcon from "@/components/svg-icons/AccountIcon.vue";
import AlertIcon from "@/components/svg-icons/AlertIcon.vue";
import HomeIcon from "@/components/svg-icons/HomeIcon.vue";
import ConfigIcon from "@/components/svg-icons/ConfigIcon.vue";
import QrCodeIcon from "@/components/svg-icons/QrCodeIcon.vue";

export default {
    props: ['miniVariant'],
    components: {QrCodeIcon, ConfigIcon, AlertIcon, HomeIcon, AccountIcon, CampaignIcon}
}
</script>

<style scoped>

</style>