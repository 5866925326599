/***
 * Admin or Agency or Regional
 */
export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: {
            title: 'Tableau de board',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('../views/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('../views/products/index.vue'),
        meta: {
            title: 'Produits',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: () => import('../views/gifts/index.vue'),
        meta: {
            title: 'Cadeaux',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/database',
        name: 'database',
        component: () => import('../views/database/index.vue'),
        meta: {
            title: 'Base de donnée',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/campaigns/create',
        name: 'campaigns.create',
        component: () => import('../views/campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'agency']
        }
    },
    {
        path: '/agencies',
        name: 'agencies',
        component: () => import('../views/agencies/index.vue'),
        meta: {
            title: 'Agences',
            roles: ['admin'],
        }
    },
    {
        path: '/regionals',
        name: 'regionals',
        component: () => import('../views/regionals/index.vue'),
        meta: {
            title: 'Régionaux',
            roles: ['agency'],
        }
    },
    {
        path: '/regionals/supervisors',
        name: 'supervisors',
        component: () => import('../views/supervisors/index.vue'),
        meta: {
            title: 'Superviseurs',
            roles: ['agency'],
        }
    },
    {
        path: '/regionals/animators',
        name: 'animators',
        component: () => import('../views/animators/index.vue'),
        meta: {
            title: 'Animateurs',
            roles: ['agency'],
        }
    },
    {
        path: '/gifty',
        component: () => import('../views/gifty/rewards/index.vue'),
        meta: {
            title: 'Gifty - Récompenses',
            roles: ['agency','admin']
        }
    },
    {
        path: '/gifty/liaisons',
        component: () => import('../views/gifty/liaisons/index.vue'),
        meta: {
            title: 'Gifty - Pos lié à Gifty',
            roles: ['agency','admin']
        }
    },
]