<template>
  <div>

    <AsideLogo :mini-variant="miniVariant"/>

    <AdminSidebar v-if="roles('admin | agency')" :mini-variant="miniVariant"/>

    <RegionalSidebar v-if="roles('regional')" :mini-variant="miniVariant"/>

    <SupervisorSidebar v-if="roles('supervisor')" :mini-variant="miniVariant"/>

    <AnimatorSidebar v-if="roles('animator')" :mini-variant="miniVariant"/>

  </div>
</template>

<script>


import AdminSidebar from "@/components/navigation/sidebar/AdminSidebar.vue";
import AsideLogo from "@/components/navigation/sidebar/AsideLogo.vue";
import SupervisorSidebar from "@/components/navigation/sidebar/SupervisorSidebar.vue";
import AnimatorSidebar from "@/components/navigation/sidebar/AnimatorSidebar.vue";
import RegionalSidebar from "@/components/navigation/sidebar/RegionalSidebar.vue";

export default {
    components: {AnimatorSidebar, SupervisorSidebar, AsideLogo, AdminSidebar, RegionalSidebar},
    props: ['miniVariant'],
}
</script>

<style>

.sidebar .v-list-item__title, .btn-add .v-list-item__title {
    font-size: 0.88rem;
    font-weight: 500
}

.sidebar.v-list--nav .v-list-item {
    border-radius: 8px;
    transition: 0.3s;
}

.sidebar.v-list--nav .v-list-item:hover {
    border-radius: 8px;
    background-color: rgba(111, 60, 251, .07);
}

.sidebar .v-list-item:hover .v-list-item__title, .sidebar .v-list-item:hover .v-list-item__avatar {
    color: #6f3cfb;
}

.sidebar .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #5d5c61;
}

.sidebar .item--active {
    background-color: rgba(111, 60, 251, .07);
}

.btn-grad {
    background-image: linear-gradient(to right, #6f3cfb 0%, #00fe8e 51%, #3b8aff 100%);
    text-transform: uppercase;
    transition: 0.8s;
    background-size: 200% auto;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
}

</style>