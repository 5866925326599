<template>
  <div id="app">
    <v-app>

      <Navigation v-if="isLoggedIn"/>

      <v-main>

        <ErrorMessage/>
        <SuccessMessage/>
        <WarningMessage/>

        <router-view/>

        <v-btn v-show="fab"
               v-scroll="onScroll"
               bottom
               color="primary"
               dark
               fab
               fixed
               right
               @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-main>

      <Footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/components/navigation/Main.vue";
import ErrorMessage from "@/components/messages/ErrorMessage.vue";
import SuccessMessage from "@/components/messages/SuccessMessage";
import Footer from "@/components/navigation/Footer";
import {HTTP} from "@/http-common";
import WarningMessage from "@/components/messages/WarningMessage.vue";

export default {
    components: {WarningMessage, Footer, SuccessMessage, ErrorMessage, Navigation},
    data() {
        return {
            route: '',
            fab: false,
            isLoggedIn: this.$store.state.isLoggedIn,
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        },
        getRoles() {
            HTTP.get('/roles').then(res => {
                this.$store.commit('roles', res.data.data)
            }).catch(err => {
                console.log(err)
            })
        },
        me() {
            HTTP.get('/me').then(res => {
                let user = res.data.data
                this.$store.dispatch('UpdateUserInfo', {
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                })
                this.$store.dispatch('UpdateUserProfile', user.photo)
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * Syncronize sales
         */
        synchronizeSales() {
            if (this.sales && this.sales.length) {
                HTTP.post('/animator-view/synchronize-sales', {
                    sales: this.sales
                }).then(() => {
                    let sales = this.$store.state.sales

                    sales.map((item1, index) => {
                        this.sales.map(item2 => {
                            if (item1.uuid === item2.uuid) {
                                this.$store.dispatch('updateSyncSale', index)
                            }
                        })
                    })
                })
            }
        },

        /**
         * Syncronize pointings
         */
        synchronizePointings() {
            if (this.pointings && this.pointings.length) {
                HTTP.post('/animator-view/synchronize-pointings', {
                    pointings: this.pointings
                }).then(() => {
                    let pointings = this.$store.state.pointings
                    pointings.map((item1, index) => {
                        this.pointings.map(item2 => {
                            if (item1.created_at === item2.created_at) {
                                this.$store.dispatch('updateSyncPointing', index)
                            }
                        })
                    })
                })
            }
        },
    },
    created() {
        if (this.$store.state.isLoggedIn) {
            this.getRoles()
            this.me()

            /**
             * Get products & brands
             */
            if (this.$store.state.user.role.key === "animator"){
                HTTP.get('/products').then((res) => {
                    this.$store.dispatch('products', res.data.data)
                })
                HTTP.get('/brands').then((res) => {
                    this.$store.dispatch('brands', res.data.data)
                })
            }

        }

        /***
         * Call function every 5 seconds
         * @type {default}
         * @private
         */
        let _vm = this
        setInterval(function () {
            _vm.synchronizePointings()
            _vm.synchronizeSales()
        }, 5000)

        console.log(
            "%cWelcome to EagleEye",
            "color:#6f3cfb;font-family:system-ui;font-size:3rem;-webkit-text-stroke: 1px black;font-weight:bold"
        );
    },
    computed: {
        sales() {
            return this.$store.state.sales.filter(el => el.campaign.id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
        pointings() {
            return this.$store.state.pointings.filter(el => el.campaign_id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
    },
}
</script>

