export default [
    {
        path: '/regional-view/dashboard',
        component: () => import('../views/regional-view/dashboard/index.vue'),
        meta: {
            title: 'Régional',
        }
    },
    {
        path: '/regional-view/campaigns',
        component: () => import('../views/regional-view/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['regional'],
        }
    },
    {
        path: '/regional-view/campaigns/:id/show',
        component: () => import('../views/regional-view/campaigns/show/index.vue'),
        meta: {
            title: 'Campagnes - Détails',
            roles: ['regional'],
        }
    },
]