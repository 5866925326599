import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import AnimatorRoutes from "@/routes/animatorRoutes";
import SupervisorRoutes from "@/routes/supervisorRoutes";
import CommonRoutes from "@/routes/commonRoutes";
import AdminRoutes from "@/routes/adminRoutes";
import RegionalRoutes from "@/routes/regionalRoutes";

Vue.use(VueRouter)

const index = [
    ...CommonRoutes,
    ...AdminRoutes,
    ...AnimatorRoutes,
    ...RegionalRoutes,
    ...SupervisorRoutes
]
const router = new VueRouter({
    mode: 'history',
    routes: index,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

/***
 * Auth
 */


router.beforeEach((to, from, next) => {

    const publicPages = ["login", "redirect"];
    const authRequired = publicPages.includes(to.name);
    const isLoggedIn = store.state.isLoggedIn;

    if (!authRequired && !isLoggedIn) {
        next("/login");
    } else if (authRequired && isLoggedIn) {

        let redirection = "/"
        const role = store.state.user.role.key

        if (role === 'agency' || role === 'regional' || role === 'admin') {
            redirection = "/"
        }

        if (role === 'regional') {
            redirection = "/regional-view/dashboard"
        }

        if (role === 'supervisor') {
            redirection = "/supervisor-view/dashboard"
        }

        if (role === 'animator') {
            redirection = "/animator-view/dashboard"
        }

        next(redirection);

    } else {
        next();
    }
});

/***
 * Page title
 */
router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + '::' + to.meta.title
    next()
});

/**
 * roles**
 **/
router.beforeEach((to, from, next) => {
    if (to.meta.roles) {
        const roles = to.meta.roles
        const roleKey = store.state.user.role.key
        const state = !!roles.includes(roleKey)
        if (state) {
            next();
        } else {
            next('/401');
        }
    } else {
        next()
    }
})

export default router
