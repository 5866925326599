<template>
  <div>
    <vue-easy-lightbox :imgs="image"
                       :index="index"
                       :visible="visible"
                       escDisabled
                       moveDisabled
                       @hide="visible = false"></vue-easy-lightbox>
  </div>
</template>

<script>
export default {
    name: "Lightbox",
    data() {
        return {
            image: '',
            visible: false,
            index: 0,
        }
    },
    methods: {
        open(image) {
            this.visible = true
            this.image = this.$baseUrl + image
        }
    }
}
</script>

<style scoped>

</style>