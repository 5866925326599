export default [
    {
        path: '*',
        component: () => import('../views/404.vue'),
        meta: {
            title: 'Page non trouvée'
        }
    },
    {
        path: '/401',
        component: () => import('../views/401.vue'),
        meta: {
            title: '401 Non autorisé'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
            title: 'Connexion'
        }
    },

    {
        path: '/account',
        component: () => import('../views/account/index.vue'),
        meta: {
            title: 'Mon compte',
        }
    },
]