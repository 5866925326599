<template>
  <v-dialog v-model="dialog"
            :max-width="options.width"
            @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <v-spacer/>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-4 text-center">

        <v-avatar :class="'bg-'+options.color+'-subtle'" size="90">
          <v-icon :color="options.color" size="50">
            {{ options.icon ? options.icon : 'mdi-delete-empty-outline' }}
          </v-icon>
        </v-avatar>


        <h2 class="my-5">{{ title ? title : 'Êtes-vous sûr?' }}</h2>

        <p> {{ message ? message : 'Vous ne pourrez pas revenir en arrière !' }}</p>

        <div v-html="others"></div>

      </v-card-text>

      <v-card-actions class="grey lighten-3" v-if="options.actions">
        <v-spacer/>
        <v-btn :color="options.color"
               class="my-2"
               dark
               depressed
               @click.native="agree">
          {{ options.agreeText ? options.agreeText : 'Supprimer' }}

          <v-icon right>mdi-hand-pointing-right</v-icon>
        </v-btn>
        <v-spacer/>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        others: null,
        options: {
            actions: true,
            agreeText: null,
            cancelText: null,
            color: 'red',
            width: 410,
            icon: null,
        }
    }),
    methods: {
        open(title, message, options, others) {
            this.others = others
            this.dialog = true
            this.title = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>