<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-user-card" height="66" style="color: rgb(111, 60, 251);" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer" d="M14.447 1.106a1 1 0 0 1 .447 1.341L14.118 4H18a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3.882l-.776-1.553a1 1 0 0 1 1.788-.894L12 3.763l1.106-2.21a1 1 0 0 1 1.341-.447Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer" d="M12 9c-1.54 0-2.502 1.667-1.732 3 .357.619 1.017 1 1.732 1 1.54 0 2.502-1.667 1.732-3A1.999 1.999 0 0 0 12 9Zm1.5 5h-3a2.5 2.5 0 0 0-2.495 2.336L8 16.5v.5a1 1 0 0 0 1.993.117L10 17v-.5a.5.5 0 0 1 .41-.492L10.5 16h3a.5.5 0 0 1 .492.41l.008.09v.5a1 1 0 0 0 1.993.117L16 17v-.5a2.5 2.5 0 0 0-2.336-2.495L13.5 14Z"
            fill="currentColor"
            fill-rule="evenodd"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>