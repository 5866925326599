<template>
  <div>
    <v-list class="sidebar" flat nav>

      <v-list-item-group :class="miniVariant ? 'pa-2' :  'pa-3'" color="primary">

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/supervisor-view/dashboard">
          <v-list-item-avatar size="24" tile>
            <HomeIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/supervisor-view/campaigns">
          <v-list-item-avatar size="24" tile>
            <CampaignIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Campagnes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/supervisor-view/gifty">
          <v-list-item-avatar size="24" tile>
            <GiftyIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Gifty</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/account">
          <v-list-item-avatar size="24" tile>
            <AccountIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Mon compte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import CampaignIcon from "@/components/svg-icons/CampaignIcon.vue";
import HomeIcon from "@/components/svg-icons/HomeIcon.vue";
import AccountIcon from "@/components/svg-icons/AccountIcon.vue";
import GiftyIcon from "@/components/svg-icons/GiftyIcon.vue";

export default {
    props: ['miniVariant'],
    components: {GiftyIcon, AccountIcon, HomeIcon, CampaignIcon}
}
</script>

<style scoped>

</style>