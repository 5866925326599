<template>
  <div>
    <v-app-bar app
               dark
               flat
               style="background: transparent linear-gradient(93deg,#6f3cfb,#00fe8e) 0 0 no-repeat padding-box;"
               tile>

      <v-app-bar-nav-icon
              @click="$vuetify.breakpoint.mobile ? $emit('mobileDrawer') : $emit('miniVariant')"></v-app-bar-nav-icon>


      <v-spacer/>

      <v-btn v-if="!$vuetify.breakpoint.mobile"
             icon
             to="/account">
        <SettingsIcon/>
      </v-btn>

      <v-btn v-if="isFullscreen"
             class="d-none d-lg-block"
             icon
             @click="minimize">
        <MinimizeIcon/>
      </v-btn>

      <v-btn v-else
             class="d-none d-lg-block"
             icon
             @click="maximize">
        <MaximizeIcon/>
      </v-btn>

      <v-toolbar-items>
        <v-menu v-model="menu"
                :close-on-content-click="false"
                bottom
                min-width="180"
                offset-y
                transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">

            <v-list-item :style="{minWidth: '180px',maxWidth: '180px', }"
                         link
                         v-bind="attrs"
                         v-on="on"
            >
              <v-list-item-avatar>
                <v-img v-if="$store.state.user.photo" :src="$baseUrl + $store.state.user.photo"></v-img>
                <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-12">
                  {{ $store.state.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="fs-12">{{ $store.state.user.role.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>


          <v-card :disabled="isLoading" :loading="isLoading">

            <v-list dense>

              <v-list-item color="primary" to="/account" @click="menu=false">
                <v-list-item-avatar>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Mon compte</v-list-item-title>
              </v-list-item>


              <v-list-item @click="logout">
                <v-list-item-avatar>
                  <v-icon>mdi-power</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Déconnexion</v-list-item-title>
              </v-list-item>

            </v-list>

          </v-card>

        </v-menu>
      </v-toolbar-items>

    </v-app-bar>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import {MaximizeIcon, MinimizeIcon, SettingsIcon} from 'vue-feather-icons'

export default {
    components: {
        MaximizeIcon,
        MinimizeIcon,
        SettingsIcon,
    },
    data() {
        return {
            menu: false,
            isLoading: false,
            isFullscreen: false,
        }
    },
    methods: {
        logout() {
            this.isLoading = true
            HTTP.post('logout').then(() => {
                this.isLoading = false
                this.$store.dispatch('user', {role: {}})
                this.$store.dispatch('isLoggedIn', false)
                this.$router.go(0)
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        maximize() {
            let el = document.documentElement;
            if (el.requestFullscreen) {
                el.requestFullscreen();
            } else if (el.webkitRequestFullscreen) { /* Safari */
                el.webkitRequestFullscreen();
            } else if (el.msRequestFullscreen) { /* IE11 */
                el.msRequestFullscreen();
            }
            this.isFullscreen = true
        },
        minimize() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
            this.isFullscreen = false
        },
    }
}
</script>

<style scoped>
</style>