import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {},
        isLoggedIn: false,
        roles: [],
        products: [],
        brands: [],

        /**
         * animator view
         */
        campaigns: [],
        programs: [],
        pointings: [],
        sales: [],
    },
    mutations: {
        user: (state, payload) => {
            state.user = payload
        },
        isLoggedIn: (state, payload) => {
            state.isLoggedIn = payload
        },
        UpdateUserProfile: (state, payload) => {
            state.user.photo = payload
        },
        UpdateUserInfo: (state, payload) => {
            state.user.name = payload.name
            state.user.email = payload.email
            state.user.phone = payload.phone
        },
        roles: (state, payload) => {
            state.roles = payload
        },
        products: (state, payload) => {
            state.products = payload
        },
        brands: (state, payload) => {
            state.brands = payload
        },

        /**
         * animator view
         */
        campaigns: (state, payload) => {
            state.campaigns = payload
        },
        programs: (state, payload) => {
            state.programs.push(payload)
        },
        updatePrograms: (state, payload) => {
            state.programs = [...state.programs.map(item => item.campaign.id !== payload.campaign.id ? item : {...item, ...payload})]
        },
        pointing: (state, payload) => {
            state.pointings.push(payload)
        },
        updateSyncPointing: (state, index) => {
            state.pointings[index].is_sync = true
        },
        addToSale: (state, payload) => {
            state.sales.push(payload)
        },
        updateSyncSale: (state, index) => {
            state.sales[index].is_sync = true
        },

    },
    actions: {
        user({commit}, payload) {
            commit("user", payload);
        },
        isLoggedIn({commit}, payload) {
            commit("isLoggedIn", payload);
        },
        UpdateUserProfile({commit}, payload) {
            commit("UpdateUserProfile", payload);
        },
        UpdateUserInfo({commit}, payload) {
            commit("UpdateUserInfo", payload);
        },
        roles({commit}, payload) {
            commit("roles", payload);
        },
        products({commit}, payload) {
            commit("products", payload);
        },
        brands({commit}, payload) {
            commit("brands", payload);
        },

        /**
         * animator view
         */
        campaigns({commit}, payload) {
            commit("campaigns", payload);
        },
        programs({commit}, payload) {
            commit("programs", payload);
        },
        updatePrograms({commit}, payload) {
            commit("updatePrograms", payload);
        },
        pointing({commit}, payload) {
            commit("pointing", payload);
        },
        updateSyncPointing({commit}, index) {
            commit("updateSyncPointing", index);
        },
        addToSale({commit}, payload) {
            commit("addToSale", payload);
        },
        updateSyncSale({commit}, index) {
            commit("updateSyncSale", index);
        },
    },
})
export default store
