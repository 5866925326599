<template>
  <div>
    <div class="text-center py-5">
      <img v-if="miniVariant"
           :src="require('@/assets/min-logo.jpg')"
           alt="logo" width="36">

      <img v-else
           :src="require('@/assets/logo.jpg')"
           alt="logo"
           width="156">
    </div>
  </div>
</template>

<script>
export default {
    props: ['miniVariant']
}
</script>

<style scoped>

</style>