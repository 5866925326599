export default [
    {
        path: '/supervisor-view/dashboard',
        component: () => import('../views/supervisor-view/dashboard/index.vue'),
        meta: {
            title: 'Dashbaord',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/campaigns',
        component: () => import('../views/supervisor-view/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/campaigns/:id/assignment',
        component: () => import('../views/supervisor-view/campaigns/assignment.vue'),
        meta: {
            title: 'Campagnes - affectation',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/campaigns/:id/sales',
        component: () => import('../views/supervisor-view/campaigns/sales/index.vue'),
        meta: {
            title: 'Campagnes - ventes',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/gifty/:id/liaison',
        component: () => import('../views/supervisor-view/gifty/liaison.vue'),
        meta: {
            title: 'Gifty - Liaison',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/gifty',
        component: () => import('../views/supervisor-view/gifty/index.vue'),
        meta: {
            title: 'Gifty - Recherche',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/gifty/rewards',
        component: () => import('../views/supervisor-view/gifty/rewards/index.vue'),
        meta: {
            title: 'Gifty - Récompenses',
            roles: ['supervisor'],
        }
    },
    {
        path: '/supervisor-view/gifty/liaisons',
        component: () => import('../views/supervisor-view/gifty/liaisons/index.vue'),
        meta: {
            title: 'Gifty - Pos lié à Gifty',
            roles: ['supervisor'],
        }
    },
]