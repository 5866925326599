export default [
    {
        path: '/animator-view/dashboard',
        component: () => import('../views/animator-view/dashboard/index.vue'),
        meta: {
            title: 'Animateur',
        }
    },
    {
        path: '/animator-view/campaigns',
        component: () => import('../views/animator-view/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['animator'],
        }
    },
    {
        path: '/animator-view/settings',
        component: () => import('../views/animator-view/setting.vue'),
        meta: {
            title: 'Paramètres',
            roles: ['animator'],
        }
    },
    {
        path: '/animator-view/campaigns/:id/program',
        component: () => import('../views/animator-view/campaigns/program/index.vue'),
        meta: {
            title: 'Campagnes - Programme',
            roles: ['animator'],
        }
    },
    {
        path: '/animator-view/qr-codes',
        component: () => import('../views/animator-view/qr-codes/index.vue'),
        meta: {
            title: 'QR codes',
            roles: ['animator'],
        }
    },
    {
        path: '/qrcode/:code',
        component: () => import('../views/animator-view/qr-codes/redirect-page.vue'),
        name: 'redirect',
        meta: {
            title: 'Redirection',
        }
    },
]